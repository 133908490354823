import * as React from 'react';
import {useEffect, useState} from 'react';
import 'whatwg-fetch'

import './styles/App.scss';

const elt = require('./images/early-logo-thing.png');
const newWordmarkVector = require('./images/new-wordmark-vector.svg');

const SocialLink: React.FC<{
    src: string, href: string, alt: string, scale?: number
}> = ({src, href, alt, scale = 1}) => {
    return <a href={href} style={{
        display: 'block',
    }}>
        <img
            style={{
                width: 60,
                height: 60,
                margin: '10px 20px',
                transform: `scale(${scale})`,
            }}
            alt={alt}
            src={src}
        />
    </a>
};

const Socials: React.FC = () => {
    return <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    }}>
        {(() => {
            const socials: [string, string, string, number][] = [
                ['SoundCloud', require('./images/icons/boundcloud-vector.svg'), 'https://soundcloud.com/snareskin', 1.2],
                ['Instagram', require('./images/icons/insta-vector.svg'), 'https://instagram.com/snareskin', .8],
                ['Facebook', require('./images/icons/faceberk-vector.svg'), 'https://facebook.com/snareskin', .8],
                ['Twitter', require('./images/icons/twooter-vector.svg'), 'https://twitter.com/snareskin', .8],
            ];
            return socials.map(([alt, src, href, scale]: [string, string, string, number]) => <SocialLink
                key={alt} alt={alt} src={src} href={href} scale={scale}
            />)
        })()}
    </div>;
};

const MusicEmbed: React.FC = () => {
    return <iframe src="https://open.spotify.com/embed/artist/5sDdzMVQgnGZ0nND3WvByB"
                   width="300" height="380" title="Spotify"
                   frameBorder="0" allow="encrypted-media"></iframe>;
};

const Wrapper: React.FC = ({children}) => {
    return <div style={{
        maxWidth: 600,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxSizing: 'border-box',
        textAlign: 'center',
        position: 'relative',
        padding: '30px 0 65px',
    }}>{children}</div>;
};

const Contact: React.FC = () => {
    return <div style={{
        justifySelf: 'flex-end',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        fontSize: '75%',
        fontFamily: 'sans-serif',
        lineHeight: 1.6,
    }}>
        <p>
            contact: snareskin@gmail.com
        </p>
    </div>;
};

const MailChimp: React.FC = () => {
    const [content, setContent] = useState<string | null>(null);
    useEffect(() => {
        (async () => {
            const response = await window.fetch(require('./mc-embed.txt'));
            setContent(await response.text());
        })();
    }, []);
    return content ? <div dangerouslySetInnerHTML={{__html: content}}/> : <div>Loading...</div>;
};

const MailChimpOverlay: React.FC<{ hide?: () => void }> = ({hide}) => {
    return <div style={{
        top: 0,
        left: 0,
        position: 'fixed',
        minWidth: '100vw',
        minHeight: '100vh',
        padding: 40,
        background: 'rgba(0,0,0,.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }} onClick={hide}>
        <div style={{
            padding: '40px 20px',
            background: 'white',
            maxWidth: 400,
        }} onClick={e => {
            e.stopPropagation();
            return false;
        }}>
            <div style={{display: 'flex', justifyContent: 'space-between', padding: 10}}>
                <h5>Join the Exclusive Punchy Snare Connoisseur Club</h5>
                <a onClick={hide} style={{cursor: 'pointer'}}>✕</a>
            </div>
            <MailChimp/>
        </div>
    </div>;
};

const Button: React.FC<{ onClick?: (e: any) => void, style?: React.CSSProperties }> = ({style, onClick, children}) => {
    return <a
        style={{
            background: 'black',
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            width: 'fit-content',
            margin: '0 auto',
            padding: '10px 20px',
            cursor: 'pointer',
            ...style,
        }}
        onClick={onClick}
    >{children}</a>;
};

const App: React.FC = () => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    return (
        <React.Fragment>
            <Wrapper>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-md"} style={{padding: '30px 20px'}}>
                            <img src={elt} alt={'SNARESKIN'}
                                 style={{width: 160, margin: '5px auto', display: 'block'}}/>
                            <img alt="SNARESKIN" src={newWordmarkVector} style={{width: 200, margin: '5px auto'}}/>
                            <Socials/>
                        </div>
                        <div className={"col-md"}
                             style={{
                                 padding: '30px 0',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center'
                             }}>
                            <MusicEmbed/>
                        </div>
                    </div>
                </div>
                <Button style={{
                    marginTop: 30, marginBottom: 40,
                }} onClick={() => setIsOverlayVisible(true)}>Join the mailing list</Button>
                <Contact/>
            </Wrapper>
            {isOverlayVisible && <MailChimpOverlay hide={() => setIsOverlayVisible(false)}/>}
        </React.Fragment>
    );
};

export default App;
